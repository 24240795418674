import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { URL_SERVICES } from '../../config/config';

@Injectable({
  providedIn: 'root'
})
export class AliateService {

  private aliatesUrl = `${URL_SERVICES}/JSih/webresources/PartnersREST/getPartnersCategories`;

  constructor(private http: HttpClient) { }

  getAliates():Observable<any>{
    return this.http.get(this.aliatesUrl).pipe(
      tap(chapters => {console.log('fetche aliates')})
    );
  }

}
