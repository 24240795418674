import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { URL_SERVICES } from '../../../config/config';

@Injectable({
  providedIn: 'root'
})
export class PublicationsService {

  private publicationsUrl = `${URL_SERVICES}/JSih/webresources/PublicationsREST/getPublications`;

  constructor(private http: HttpClient) { }

  getPublications():Observable<any>{
    return this.http.get(this.publicationsUrl).pipe(
      tap(chapters => {console.log('fetche publications')})
    );
  }
}
