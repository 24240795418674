import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { URL_SERVICES } from '../../config/config';

const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'text/plain' }) };

@Injectable({
  providedIn: 'root'
})
export class AffiliatesService {

  private subcategoriesUrl = `${URL_SERVICES}/JSih/webresources/UtilityREST/getSubcategories`;
  private chaptersUrl = `${URL_SERVICES}/JSih/webresources/ChapterREST/getChapters`;
  private validateRntUrl = `${URL_SERVICES}/JSih/webresources/ValidatorREST/validateRntIfExist`;
  private validateMailUrl = `${URL_SERVICES}/JSih/webresources/ValidatorREST/validateMailIfExist`;
  private affiliatesUrl = `${URL_SERVICES}/JSih/webresources/AffiliateREST/getAffiliates`;
  private affiliatesRamdomUrl = `${URL_SERVICES}/JSih/webresources/AffiliateREST/getRamdomAffiliates`;

  private registerAffiliateUrl = `${URL_SERVICES}/JSih/webresources/AffiliateREST`;

  constructor(private http: HttpClient) { }

  getSubCategories(): Observable<any> {
    return this.http.get(this.subcategoriesUrl).pipe(
      tap(subcategories => {console.log('fetche subcategories');})
    );
  }

  getChapters(): Observable<any> {
    return this.http.get(this.chaptersUrl).pipe(
      tap(chapters => {console.log('fetche chapters');})
    );
  }

  async validateRnt(rnt): Promise<any> {

    const uri = this.validateRntUrl + '/' + rnt;

    try {
      const response = await this.http
        .get(uri)
        .toPromise();
      return response;
    } catch (error) {
      await 'error';
    }

  }

  async validateMail(email): Promise<any> {

    const uri = this.validateMailUrl + '/' + email;
    console.log(uri);

    try {
      const response = await this.http
        .get(uri)
        .toPromise();
      return response;
    } catch (error) {
      return await 'error';
    }
  }

  getAffiliates(): Observable<any> {
     return this.http.get(this.affiliatesUrl).pipe(
      tap(events => console.log(`fetched affiliates`))
    );
  }

  getAffiliatesRamdom(): Observable<any> 
  {
    return this.http.get(this.affiliatesRamdomUrl).pipe(
     tap(events => console.log(`fetched affiliates`))
   );
 }

  storeAfilliate(data): Observable<any> {
    return this.http.post(this.registerAffiliateUrl, data, httpOptions).pipe(
      tap(events => {
        console.log(`register affiliate`, events);
        return events;
      })
    );
  }

}


