import { Injectable } from '@angular/core';

import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { delay } from 'rxjs/internal/operators';
import { ConsultancyService } from '../../../services/consultancy/consultancy.service';

@Injectable()
export class ConsultancyResolver implements Resolve<any> {
    constructor(private trainingService: ConsultancyService) {}
  
    resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> {
      return this.trainingService.getConsultanciyByToken(route.paramMap.get('token'));
    }
  }