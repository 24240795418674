import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from './safehtml.pipe';

@NgModule({
  imports: [
  ],
  declarations: [
    SafeHtmlPipe
    //BillboardComponent
  ],
  exports:[
    SafeHtmlPipe
  ]
})
export class PipesModule { }