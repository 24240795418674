import { Injectable } from '@angular/core';
import { URL_SERVICES } from '../../config/config';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {

  private trainingsUrl = `${URL_SERVICES}/JSih/webresources/businessConferenceREST/getBusinessConferences`;
   private getTrainingsByTokenUrl = `${URL_SERVICES}/JSih/webresources/businessConferenceREST/getBusinessConferenceBySlug`

  constructor(private http: HttpClient) { }

  getBusiness():Observable<any>{
    return this.http.get( this.trainingsUrl ).pipe(
      tap(subcategories => {console.info('fetche trainings')})
    );
  }

  getBusinessBySlug(slug):Observable<any>{
    return this.http.get( this.getTrainingsByTokenUrl +'/' + slug ).pipe(
      tap(subcategories => {console.info('fetche trainings')})
    );
  }
}
