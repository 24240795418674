import { Injectable } from '@angular/core';

import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { EventsService } from '../../../services/events.service';

@Injectable()
export class EventsResolver implements Resolve<any> {
    constructor(private eventsService: EventsService) {}
  
    resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> {
      let token = route.paramMap.get('token')
      if (['EVENT', 'CONGRESS', 'ASSEMBLY'].includes(token)) {
        return this.eventsService.getEventByType(token);
      }else{
        return this.eventsService.getEvent(token)
      }
    }
  }