import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';


import { URL_SERVICES } from '../../config/config';

@Injectable({
  providedIn: 'root'
})
export class LibraryService {

  private librariesUrl = `${URL_SERVICES}/JSih/webresources/GeneralDocumentsREST/getDocumentsInstitutionnels`;
  private categoriesUrl = `${URL_SERVICES}/JSih/webresources/GeneralDocumentsREST/getCategoriesDocs`;
  private subCategoriesUrl = `${URL_SERVICES}/JSih/webresources/GeneralDocumentsREST/getSubcategoriesDocs`;
  private topicsUrl = `${URL_SERVICES}/JSih/webresources/GeneralDocumentsREST/getTopicsDocs`;
  private subCategoriesByCategory = `${URL_SERVICES}/JSih/webresources/GeneralDocumentsREST/getSubcategoriesByCategoryDocs`;
  private topicsBySubCategory = `${URL_SERVICES}/JSih/webresources/GeneralDocumentsREST/getTopicBySubcategoryDocs`;
  private searchFilter = `${URL_SERVICES}/JSih/webresources/GeneralDocumentsREST/searchDocs`;
  private library = `${URL_SERVICES}/JSih/webresources/GeneralDocumentsREST/getDocument`

  constructor(private http: HttpClient) { }

  getLibraries():Observable<any>{
    return this.http.get( this.librariesUrl).pipe(
      tap(chapters => {console.log('fetches libraries')})
    );
  }

  getLibrary(id):Observable<any>{
    return this.http.get( this.library+'/'+id ).pipe(
      tap(chapters => {console.log('fetc librari')})
    );
  }

  getCategories():Observable<any>{
    return this.http.get( this.categoriesUrl ).pipe(
      tap(chapters => {console.log('fetches categories')})
    );
  }
  getSubCategories():Observable<any>{
    return this.http.get( this.subCategoriesUrl ).pipe(
      tap(chapters => {console.log('fetches subcategories')})
    );
  }

  getTopics():Observable<any>{
    return this.http.get( this.topicsUrl ).pipe(
      tap(chapters => {console.log('fetches topics')})
    );
  }

  getSubCategoriesByCategory(categoryId):Observable<any>{
    return this.http.get( this.subCategoriesByCategory + `/${categoryId}` ).pipe(
      tap(chapters => {console.log('fetches subcategories by category')})
    );
  }

  getTopicsBySubCategory(subCategoryId):Observable<any>{
    return this.http.get( this.topicsBySubCategory + `/${subCategoryId}` ).pipe(
      tap(chapters => {console.log('fetches topics by subcategory')})
    );
  }

  getSearchFilter(categoryId,subCategoryId, topicId, keyword):Observable<any>{
    
    let category = categoryId > 0 ? categoryId : null;
    let subCcategory = subCategoryId > 0 ? subCategoryId : null;
    let topic = topicId > 0 ? topicId : null;
    let key = keyword != '' ? keyword : null;

    return this.http.get( this.searchFilter + `/${category}/${subCcategory}/${topic}/${key}` ).pipe(
      tap(chapters => {console.log('fetches searchs filter')})
    );
    
  }

}
