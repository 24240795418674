import { Injectable } from '@angular/core';

import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { LibraryService } from '../../../services/library/library.service';

@Injectable()
export class LibraryResolver implements Resolve<any> {
    constructor(private libraryService: LibraryService) {}
  
    resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> {
      return this.libraryService.getLibrary(route.paramMap.get('id'));
    }
  }