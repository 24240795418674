import { Injectable } from '@angular/core';

import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { EventsService } from '../../../services/events.service';

@Injectable()
export class FeriaResolver implements Resolve<any> {
    constructor(private eventsService: EventsService) {}
  
    resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> {
      return this.eventsService.getFairsBySlug(route.paramMap.get('token'));
    }
  }