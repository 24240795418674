export class DataUtis
{
    public static isNullOrEmpty(data: any): boolean 
    {
      if (data === null || data === '' || data === 'null' || data === 'undefined' || data === undefined) { return true };
      return false;
    }

    public static formatNumber(value:string):any
    {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");;
    }
}