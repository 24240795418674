import { Injectable } from '@angular/core';

import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { BusinessService } from '../../../services/business/business.service';

@Injectable()
export class BusinessResolver implements Resolve<any> {
    constructor(private businessService: BusinessService) {}
  
    resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> {
      return this.businessService.getBusinessBySlug(route.paramMap.get('token'));
    }
  }