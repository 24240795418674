import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { URL_SERVICES } from '../../config/config';
import { ResponseContentType } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class AboutService {

  private officialsUrl = `${URL_SERVICES}/JSih/webresources/UserREST/getFunctionariesCotelco`;
  private directorssUrl = `${URL_SERVICES}/JSih/webresources/UserREST/getDirectotsCotelco`;
  private documentsUrl = `${URL_SERVICES}/JSih/webresources/GeneralDocumentsREST/getDocumentsInstitutionnels`;

  constructor(private http: HttpClient) { }

  getOfficials():Observable<any>
  {
    return this.http.get(this.officialsUrl).pipe(
      tap(chapters => {console.log('fetche officials')})
    );
  }

  getDirectors():Observable<any>
  {
    return this.http.get(this.directorssUrl).pipe(
      tap(chapters => {console.log('fetche officials')})
    );
  }

  getDocuments():Observable<any>
  {
    return this.http.get(this.documentsUrl).pipe(
      tap(chapters => {console.log('fetche documents')})
    );
  }

  getDocument(url):Observable<any>{

    return this.http.get(url)
  }

}
