import { Injectable } from '@angular/core';

import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { TrainingsService } from '../../../services/trainings/trainings.service';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/internal/operators';

@Injectable()
export class TrainingsResolver implements Resolve<any> {
    constructor(private trainingService: TrainingsService) {}
  
    resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> {
      return this.trainingService.getTrainingsByToken(route.paramMap.get('token'));
    }
  }