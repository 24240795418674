import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { URL_SERVICES } from '../../config/config';

@Injectable({
  providedIn: 'root'
})
export class ChapterService {


  chapterUrl = `${URL_SERVICES}/JSih/webresources/ChapterREST/getChapter`;

  constructor(private http: HttpClient) { }

  getChapter(id):Observable<any>{
    return this.http.get(`${this.chapterUrl}/${id}`).pipe(
      tap(chapters => {console.log('fetche chapter')})
    );
  }

}
